import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Works from "../components/works"
import Featured from "../components/featured"

const IndexPage = () => (
  <Layout hero={true}>
    <SEO title="Bra kaffe varje månad" />
    <h1 className="pt-3">Våra smakpaket</h1>
    <Featured />
    <Works />
  </Layout>
)

export default IndexPage
